<template>
    <v-card>
        <v-toolbar color="primary" dark>
            <v-toolbar-title>{{$t('hotel_customer_form')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="dialogueClose" dark icon>
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-items>
                <v-btn @click="reset" dark text v-if="snackbar.save_button">{{$t('save')}}</v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-stepper v-model="e6" vertical>
            <v-stepper-step :complete="e6 > 1" step="1">
                {{$t('customer_form')}}
                <small>{{$t('fill_out_the_customer_record_form')}}</small>
            </v-stepper-step>

            <v-stepper-content step="1">
                <form class="px-2">
                    <v-row class="pt-4">
                        <v-col cols="12" md="4">
                            <v-text-field :error-messages="NumberOfPersonErrors"
                                          @blur="$v.formData_1.NumberOfPerson.$touch()"
                                          @input="$v.formData_1.NumberOfPerson.$touch()"
                                          clearable
                                          outlined
                                          dense
                                          :label="$t('number_of_person')"
                                          required
                                          v-model="formData_1.NumberOfPerson"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="2">
                            <v-menu :close-on-content-click="false"
                                    :nudge-right="40"
                                    min-width="290px"
                                    offset-y
                                    transition="scale-transition"
                                    v-model="snackbar.menu2">
                                <template v-slot:activator="{ on }">
                                    <v-text-field clearable
                                                  :label="$t('check_in_date')"
                                                  prepend-inner-icon="event"
                                                  readonly
                                                  v-model="formData_1.CheckInDate"
                                                  v-on="on"
                                                  outlined
                                                  dense></v-text-field>
                                </template>
                                <v-date-picker :error-messages="CheckInDateErrors" @blur="$v.formData_1.CheckInDate.$touch()"
                                               @input="$v.formData_1.CheckInDate.$touch()"
                                               v-model="formData_1.CheckInDate"></v-date-picker>

                            </v-menu>
                        </v-col>

                        <v-col cols="12" md="2">
                            <v-menu :close-on-content-click="false"
                                    :nudge-right="40"
                                    min-width="290px"
                                    offset-y
                                    transition="scale-transition"
                                    v-model="snackbar.menu3">
                                <template v-slot:activator="{ on }">
                                    <v-text-field clearable
                                                  :label="$t('check_out_date')"
                                                  prepend-inner-icon="event"
                                                  readonly
                                                  v-model="formData_1.CheckOutDate"
                                                  v-on="on"
                                                  outlined
                                                  dense></v-text-field>
                                </template>
                                <v-date-picker :error-messages="CheckOutDateErrors" @blur="$v.formData_1.CheckOutDate.$touch()"
                                               @change="GetRoomStatus"
                                               @input="$v.formData_1.CheckOutDate.$touch()"
                                               v-model="formData_1.CheckOutDate"></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-autocomplete :error-messages="RoomErrors"
                                            :items="rooms"
                                            @blur="$v.formData_1.Room.$touch()"
                                            @input="$v.formData_1.Room.$touch()"
                                            chips
                                            clearable
                                            deletable-chips
                                            item-text="roomDisplay"
                                            item-value="roomID"
                                            :label="$t('rooms')"
                                            multiple
                                            v-model="formData_1.Room"
                                            outlined
                                            dense></v-autocomplete>
                        </v-col>
                        

                        <!--                        <v-autocomplete v-model="formData_1.Package"-->
                        <!--                                        :items="packages"-->
                        <!--                                        label="Package"-->
                        <!--                                        clearable-->
                        <!--                                        single-line-->
                        <!--                                        item-text="value"-->
                        <!--                                        item-value="id"-->
                        <!--                                        class="col-sm-6 col-md-4"-->
                        <!--                                        @change="getPrice"></v-autocomplete>-->

                        <!--                        <v-autocomplete v-model="formData_1.Service"-->
                        <!--                                        :items="services"-->
                        <!--                                        chips-->
                        <!--                                        deletable-chips-->
                        <!--                                        label="Services"-->
                        <!--                                        clearable-->
                        <!--                                        multiple-->
                        <!--                                        item-text="value"-->
                        <!--                                        item-value="id"-->
                        <!--                                        class="col-sm-6 col-md-4"-->
                        <!--                                        @change="getServicePrice"></v-autocomplete>-->

                        <!--                        <v-switch class="col-sm-6 col-md-4"-->
                        <!--                                  v-model="formData_1.isCustomerOnPackage"-->
                        <!--                                  label="Is Customer On Package?"-->
                        <!--                                  clearable-->
                        <!--                                  @change="getPrice"></v-switch>-->

                        <div class="col-12">
                            <!--                            <v-col md="4">-->

                            <!--                                Total Fare : RS {{snackbar.price}}-->
                            <!--                            </v-col>-->
                            <!--                            <v-col md="4">-->
                            <!--                                Service Charge: RS {{snackbar.ServicePrice}}-->

                            <!--                            </v-col>-->
                            <!--                            <br/>-->
                            <v-btn @click="form_1Post" class="mr-4 green darken-1" dark depressed>{{$t('save')}}</v-btn>
                            <!-- <v-btn dark depressed class="mr-4 blue darken-1" @click="form_next" :disabled="!snackbar.next_button">Next</v-btn>-->
                        </div>

                    </v-row>
                </form>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 2" step="2">{{$t('customer_information')}}</v-stepper-step>

            <v-stepper-content step="2">
                <v-expansion-panels>
                    <v-expansion-panel :key="i" v-for="(item,i) in snackbar.number_of_second_form">
                        <v-expansion-panel-header>{{$t('person')}} {{item}}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <form class="px-2">
                                <v-card class="my-12">
                                    <v-toolbar class="darken-1" color="blue" dark fixed>
                                        <v-toolbar-title>
                                            {{$t('find_person_id')}}
                                        </v-toolbar-title>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-select
                                                        :items="['Passport','Citizen']"
                                                        :label="$t('type')"
                                                        dense
                                                        outlined
                                                        v-model="trackType"
                                                >
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                        :label="$t('enter_your_id')"
                                                        dense
                                                        outlined
                                                        v-model="trackID"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col class="d-flex" cols="12" sm="12">
                                                <v-spacer></v-spacer>
                                                <v-btn @click="getUserData(i)" class="mx-2" color="primary" dark small>
                                                    <v-icon dark> search</v-icon>
                                                    {{$t('search')}}
                                                </v-btn>
                                                <v-spacer></v-spacer>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-text-field clearable
                                                      :label="$t('full_name')"
                                                      dense
                                                      outlined
                                                      v-model="formData_2[i].fullName"></v-text-field>
                                    </v-col>


                                    <v-col cols="12" md="4">
                                        <v-autocomplete :items="countries"
                                                        @change="getState"
                                                        clearable
                                                        dense
                                                        outlined
                                                        item-text="value"
                                                        item-value="id"
                                                        :label="$t('country')"
                                                        single-line
                                                        v-model="formData_2[i].country"></v-autocomplete>
                                    </v-col>

                                    <!--<v-autocomplete
            :items="state"
            class="col-sm-6 col-md-4"
            clearable
            item-text="value"
            item-value="id"
            :label="$t('state')"
            single-line
            v-model="formData_2[i].state"
    ></v-autocomplete>-->
                                    <v-col cols="12" md="4">
                                        <v-autocomplete :items="genderList"
                                                        clearable
                                                        dense
                                                        outlined
                                                        item-text="value"
                                                        item-value="id"
                                                        :label="$t('gender')"
                                                        single-line
                                                        v-model="formData_2[i].gender"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field dense
                                                      outlined
                                                      clearable
                                                      :label="$t('age')"
                                                      v-model="formData_2[i].age"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field dense
                                                      outlined
                                                      clearable
                                                      :label="$t('citizenshipNumber')"
                                                      v-model="formData_2[i].citizenShipNumber"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field dense
                                                      outlined
                                                      clearable
                                                      :label="$t('passport_number')"
                                                      v-model="formData_2[i].passportNumber"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field dense
                                                      outlined
                                                      clearable
                                                      :label="$t('licenseNumber')"
                                                      v-model="formData_2[i].licenseNumber"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field dense
                                                      outlined
                                                      clearable
                                                      :label="$t('otherID')"
                                                      v-model="formData_2[i].otherID"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="4">
                                        <v-menu :close-on-content-click="false"
                                                :nudge-right="40"
                                                min-width="290px"
                                                offset-y
                                                transition="scale-transition"
                                                v-model="snackbar.dateMenu1[i]">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field clearable
                                                              :label="$t('check_in_date')"
                                                              prepend-inner-icon="event"
                                                              readonly
                                                              dense
                                                              outlined
                                                              v-model="formData_2[i].HotelInformation.CheckInDate"
                                                              v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="formData_2[i].HotelInformation.CheckInDate"></v-date-picker>

                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-menu :close-on-content-click="false"
                                                :nudge-right="40"
                                                min-width="290px"
                                                offset-y
                                                transition="scale-transition"
                                                v-model="snackbar.dateMenu2[i]">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field clearable
                                                              :label="$t('check_out_date')"
                                                              prepend-inner-icon="event"
                                                              readonly
                                                              dense
                                                              outlined
                                                              v-model="formData_2[i].HotelInformation.CheckOutDate"
                                                              v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker changeCustomerFormCheckoutDate
                                                           v-model="formData_2[i].HotelInformation.CheckOutDate"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field dense
                                                      outlined
                                                      clearable
                                                      :label="$t('visa_validity')"
                                                      readonly
                                                      v-if="formData_2[i].passportNumber"
                                                      v-model="validaty[i].visaValidity"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="4">
                                        <v-text-field dense
                                                      outlined
                                                      clearable
                                                      :label="$t('id_validity')"
                                                      readonly
                                                      v-if="formData_2[i].passportNumber"
                                                      v-model="validaty[i].visaValidity"></v-text-field>
                                    </v-col>


                                    <v-col cols="12" md="4">
                                        <v-autocomplete :items="selectedRoomData[i].selectedData"
                                                        chips
                                                        dense
                                                        outlined
                                                        clearable
                                                        deletable-chips
                                                        item-text="roomDisplay"
                                                        item-value="roomID"
                                                        :label="$t('rooms')"
                                                        v-model="formData_2[i].HotelInformation.RoomID"></v-autocomplete>
                                    </v-col>
                                    
                                </v-row>
                            </form>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <div class="col-4">
                    <v-btn @click="form_2Post" class="mr-4 green darken-1" dark depressed> {{$t('save')}}</v-btn>
                </div>
            </v-stepper-content>
            <!--            <v-stepper-step :complete="e6 > 3" step="3">Bill</v-stepper-step>-->

            <!--            <v-stepper-content step="3">-->
            <!--                <v-form>-->
            <!--                    <v-container>-->
            <!--                        <v-row>-->
            <!--                            <v-col cols="12" sm="6" md="4">-->
            <!--                                <v-text-field :value="snackbar.price+snackbar.ServicePrice " label="Price To Be Paid"-->
            <!--                                              disabled></v-text-field>-->
            <!--                            </v-col>-->
            <!--                            <v-col cols="12" sm="6" md="4">-->
            <!--                                <v-text-field v-model="BillForm.PaidAmount" label="Paid Amount"></v-text-field>-->
            <!--                            </v-col>-->
            <!--                            <v-col cols="12" sm="6" md="4">-->
            <!--                                <v-autocomplete v-model="BillForm.PaymentType" :items="paymentType"-->
            <!--                                                label="Payment Type"></v-autocomplete>-->
            <!--                            </v-col>-->

            <!--                            <v-col cols="12" sm="6" md="4">-->
            <!--                                <v-text-field v-model="BillForm.DueAmount" label="Due Amount"></v-text-field>-->
            <!--                            </v-col>-->
            <!--                            <v-col cols="12"></v-col>-->
            <!--                        </v-row>-->
            <!--                    </v-container>-->
            <!--                </v-form>-->
            <!--                <div class="col-4">-->
            <!--                    <v-btn-->
            <!--                            dark-->
            <!--                            depressed-->
            <!--                            class="mr-4 green darken-1"-->
            <!--                            @click="form_3Post"-->
            <!--                            :disabled="!snackbar.next_button"-->
            <!--                    >Save-->
            <!--                    </v-btn>-->
            <!--                </div>-->
            <!--            </v-stepper-content>-->
        </v-stepper>
        <v-snackbar :timeout="snackbar.timeout" color="error" top v-model="snackbar.snackbar">
            {{ snackbar.text }}
            <v-btn @click="snackbar.snackbar = false" text> {{$t('close')}}</v-btn>
        </v-snackbar>
    </v-card>
</template>

<script>
    /*import { email, maxLength,, sameAs } from "vuelidate/lib/validators";*/
    import axios from "axios";
    import {required} from "vuelidate/lib/validators";

    export default {
        name: "CreateHotelCustomerForm",
        props: ["pendingData"],
        watch: {
            searchCitizen(val) {

                console.log(val)
                if (val[0]) {
                    this.items = []
                    // Items have already been loaded
                    if (this.items.length > 0) return

                    // Items have already been requested
                    if (this.isLoading) return

                    this.isLoading = true

                    // Lazily load input items

                    axios.get("Track/GetCitizenIDListAsync", {
                        params: {
                            CitizenID: val[0]
                        }
                    })
                        .then(res => {
                            this.citizenshipData = res.data
                        })
                        .catch(err => {
                            console.log(err)
                        })
                        .finally(() => (this.isLoading = false))
                }
            },
            searchPassport(val) {
                console.log(val)
                if (val[0]) {
                    this.items = []
                    // Items have already been loaded
                    if (this.items.length > 0) return

                    // Items have already been requested
                    if (this.isLoading) return

                    this.isLoading = true

                    // Lazily load input items
                    axios.get("Track/GetPassportIDListAsync", {
                        params: {
                            PassportNumber: val[0]
                        }
                    })
                        .then(res => {
                            this.passportData = res.data
                        })
                        .catch(err => {
                            console.log(err)
                        })
                        .finally(() => (this.isLoading = false))
                }
            },
        },
        data() {
            return {
                storeRoom: [],
                trackType: "Passport",
                trackID: "",
                citizenshipData: [],
                passportData: [],
                dropdownData: [],
                isLoading: false,
                searchCitizen: "",
                searchPassport: "",
                e6: 1,
                step: 1,
                snackbar: {
                    snackbar: false,
                    text: "Internal Server Error,Please Contact Admin",
                    timeout: 200000,
                    menu2: false,
                    menu3: false,
                    dateMenu4: [],
                    dateMenu3: false,
                    dateMenu1: [],
                    dateMenu2: false,
                    save_button: false,
                    next_button: true,
                    success: false,
                    error: false,
                    number_of_second_form: 1,
                    HotelCustomerID: 1,
                    price: 0,
                    ServicePrice: 0
                },
                servicesData: {
                    data: {},
                    message: "Cancelled",
                    color: "red"
                },
                paymentType: ['CASH', 'CHEQUE', 'CARD'],
                formData_1: {
                    CheckInDate: "",
                    CheckOutDate: "",
                },
                formData_2: [],
                formData_2_Object: {},
                formDatas: {
                    CheckInDate: "",
                    CheckOutDate: "",
                },
                BillForm: {},
                rooms: [],
                services: [],
                packages: [],
                RoomObject: {},
                countries: [],
                state: [],
                genderList: [],
                selectedRoomData: [],
                validaty: []
            };
        },

        computed: {
            // selectedRoomData(){
            //     let allRoom = this.rooms
            //     let selectedData = []
            //     allRoom.filter(res=>{
            //         if(this.storeRoom){
            //             this.storeRoom.filter(rooms=>{
            //                 console.log("filter",rooms,res.roomID,parseInt(this.formData_1.Room)===res.roomID)
            //                 if( parseInt(rooms) == res.roomID){
            //                     selectedData.push(res)
            //                 }
            //             })
            //         }
            //     })
            //     console.log(this.formData_1.Room,allRoom,selectedData)
            //     return selectedData
            // },
            NumberOfPersonErrors() {
                const errors = [];
                if (!this.$v.formData_1.NumberOfPerson.$dirty) return errors;
                !this.$v.formData_1.NumberOfPerson.required &&
                errors.push("Number of person is required");
                return errors;
            },
            CountryErrors() {
                const errors = [];
                if (!this.$v.formData_1.Country.$dirty) return errors;
                !this.$v.formData_1.Country.required &&
                errors.push("Country is required");
                return errors;
            },
            CheckInDateErrors() {
                const errors = [];
                if (!this.$v.formData_1.CheckInDate.$dirty) return errors;
                !this.$v.formData_1.CheckInDate.required &&
                errors.push("Check in date is required");
                return errors;
            },
            CheckOutDateErrors() {
                const errors = [];
                if (!this.$v.formData_1.CheckOutDate.$dirty) return errors;
                !this.$v.formData_1.CheckOutDate.required &&
                errors.push("Check Out date is required");
                return errors;
            },
            RoomErrors() {
                const errors = [];
                if (!this.$v.formData_1.Room.$dirty) return errors;
                !this.$v.formData_1.Room.required &&
                errors.push("Room is required");
                return errors;
            },
        },
        validations: {
            formData_1: {
                NumberOfPerson: {required},
                CheckInDate: {required},
                CheckOutDate: {required},
                Room: {required}
            }
        },
        mounted() {
            this.formData();
            this.addForm_2_Parameter();
        },

        methods: {
            changeCustomerFormCheckoutDate(index) {
                let arr = []
                this.selectedRoomData[index].selectedData.filter(response => {
                    arr.push(response.roomID)
                })
                let roomID = arr.join(",")
                let data = {
                    "bookedFromDate": this.formData_2[index].HotelInformation.CheckInDate,
                    "bookedToDate": this.formData_2[index].HotelInformation.CheckOutDate,
                    "isDropDown": true,
                    "RoomIDList": roomID
                };
                axios.post(
                    "Hotel/RoomStatusListAsync",
                    data
                ).then(response => {
                    this.selectedRoomData[index].selectedData = response.data;
                }).catch(err => {
                    console.log(err)
                })
            },

            getUserData(index) {
                if (this.trackID) {
                    this.userInfo = []
                    axios.get('Track/GetPersonInformationByID', {
                        params: {
                            ID: this.trackID,
                            Type: this.trackType
                        }
                    }).then(response => {
                        console.log(this.formData_2[index], response.data)
                        this.formData_2[index].fullName = response.data.fullName
                        this.formData_2[index].firstName = response.data.firstName
                        this.formData_2[index].lastName = response.data.lastName
                        this.formData_2[index].country = response.data.country
                        this.formData_2[index].state = response.data.state
                        this.formData_2[index].gender = response.data.gender
                        this.formData_2[index].age = response.data.age
                        this.formData_2[index].citizenShipNumber = response.data.citizenShipNumber
                        this.formData_2[index].passportNumber = response.data.passportNumber
                        this.formData_2[index].passportNumber = response.data.passportNumber
                        this.formData_2[index].licenseNumber = response.data.licenseNumber
                        if (response.data.country) {
                            this.getState(response.data.country)
                        }
                        this.trackID = ""
                    })
                }
            },

            getCitizenData(index, data) {
                console.log("citizen", index, data)
                axios.get("Shared/GetPersonInformationByCitizenshipAsync", {
                    params: {
                        CitizenShipNumber: data.value
                    }
                }).then(response => {
                    this.formData_2[index] = response.data
                    if (response.data.country) {
                        this.getState(response.data.country)
                    }
                }).catch(err => {
                    console.log(err)
                });
            },

            getPassportData(index, data) {
                console.log("passport", index, data)
                axios.get("Shared/GetPersonInformationByPassportAsync", {
                    params: {
                        PassportNumber: data.value
                    }
                }).then(response => {
                    this.formData_2[index] = response.data
                    if (response.data.country) {
                        this.getState(response.data.country)
                    }
                }).catch(err => {
                    console.log(err)
                });
            },

            async getState(val) {
                const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode=' + val);
                this.state = stateList.data
            },
            dialogueClose() {
                this.$emit("formResponse", this.servicesData);
            },
            addForm_2_Parameter() {
                let allRoom = this.rooms
                let selectedData = []
                allRoom.filter(res => {
                    if (this.storeRoom) {
                        this.storeRoom.filter(rooms => {
                            if (parseInt(rooms) == res.roomID) {
                                selectedData.push(res)
                            }
                        })
                    }
                })
                this.selectedRoomData.push({
                    selectedData
                })
                this.validaty.push({
                    IDValidity: "",
                    VisaValidity: "",
                })
                this.formData_2.push({
                    fullName:"",
                    firstName: "",
                    lastName: "",
                    country: this.countries,
                    state: "",
                    gender: "",
                    age: "",
                    citizenShipNumber: "",
                    passportNumber: "",
                    licenseNumber: "",
                    otherID: "",
                    Document: "",
                    IsVerifiedDocument: false,
                    HotelInformation: {
                        RoomID: "",
                        CheckInDate: this.formDatas.CheckInDate,
                        CheckOutDate: this.formDatas.CheckOutDate,
                    }
                });
                this.dropdownData.push({
                    citizenShipNumber: null,
                    passportNumber: null
                })
            },
            async formData() {

                // const packagelist = await axios.get("Hotel/DDLPackageListAsync");
                // const servicelist = await axios.get("Hotel/DDLHotelServiceListAsync");
                axios.get("Shared/CountryList").then(response => {
                    this.countries = response.data;
                }).catch(err => {
                    throw err
                })

                axios.get("Shared/GenderList").then(res => {
                    this.genderList = res.data;
                }).catch(err => {
                    throw err
                })

            },
            getServicePrice() {
                let param = {
                    "HotelCustomerServiceID": this.formData_1.Service
                }
                axios
                    .post("Hotel/GetHotelServiceAmount", param)
                    .then(response => {
                        this.snackbar.ServicePrice = response.data;
                        console.log(this.snackbar.price);
                    })
                    .catch(() => {
                        this.snackbar.error = true;
                        this.snackbar.snackbar = true;
                        this.snackbar.success = false;
                        this.snackbar.text = "Could not Fetch the price";
                    });
            },
            getPackagePrice(val) {
                console.log(val);
                axios
                    .post("Hotel/GetHotelPakageAmount", val)
                    .then(response => {
                        this.snackbar.price = response.data;
                        console.log(this.snackbar.price);
                    })
                    .catch(() => {
                        this.snackbar.error = true;
                        this.snackbar.snackbar = true;
                        this.snackbar.success = false;
                        this.snackbar.text = "Could not Fetch the price";
                    });
            },
            getPrice() {

                let fareAmountParam = {
                    "roomIDs": this.formData_1.Room,
                    "packageID": parseInt(this.formData_1.Package),
                    "isCustomerOnPackage": this.formData_1.isCustomerOnPackage,
                    "NumberOfPerson": parseInt(this.formData_1.NumberOfPerson)
                }
                axios
                    .post("Hotel/GetPackageOrRoomFareAmountAsync", fareAmountParam)
                    .then(response => {
                        this.snackbar.price = response.data;
                        console.log(this.snackbar.price);
                    })
                    .catch(() => {
                        this.snackbar.error = true;
                        this.snackbar.snackbar = true;
                        this.snackbar.success = false;
                        this.snackbar.text = "Could not Fetch the price";
                    });
            },
            form_1Post() {
                this.$v.formData_1.$touch();
                if (this.$v.formData_1.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    this.formDatas = {
                        NumberOfPerson: parseInt(this.formData_1.NumberOfPerson),
                        // Country: this.formData_1.Country,
                        // State: this.formData_1.State,
                        // Address: this.formData_1.Address,
                        // ContactNumber: this.formData_1.ContactNumber,
                        CheckInDate: this.formData_1.CheckInDate,
                        CheckOutDate: this.formData_1.CheckOutDate,
                        Room: this.formData_1.Room,
                        // HotelCustomerServiceID: this.formData_1.Service,
                        // HotelPackageID: this.formData_1.Package,
                        // IsCustomerOnPackage: this.formData_1.IsCustomerOnPackage
                    };
                    this.storeRoom = this.formData_1.Room
                    axios
                        .post("Hotel/InsertIntoHotelCustomerAsync", this.formDatas)
                        .then(response => {
                            console.log(response.data);
                            this.$v.formData_1.$reset();
                            this.snackbar.success = response.data.success;
                            this.snackbar.number_of_second_form = this.formDatas.NumberOfPerson;
                            this.snackbar.next_button = !this.snackbar.next_button;
                            this.snackbar.HotelCustomerID = response.data.id;
                            this.snackbar.error = false;
                            this.snackbar.snackbar = false;
                            for (
                                let step = 1;
                                step < this.snackbar.number_of_second_form;
                                step++
                            ) {
                                console.log("inside loop", step);
                                this.addForm_2_Parameter();
                            }
                            this.formData_2[0].HotelInformation.CheckInDate = this.formDatas.CheckInDate
                            this.formData_2[0].HotelInformation.CheckOutDate = this.formDatas.CheckOutDate

                            let allRoom = this.rooms
                            let selectedData = []
                            allRoom.filter(res => {
                                if (this.storeRoom) {
                                    this.storeRoom.filter(rooms => {
                                        if (parseInt(rooms) == res.roomID) {
                                            selectedData.push(res)
                                        }
                                    })
                                }
                            })

                            this.selectedRoomData[0].selectedData = selectedData
                            this.e6++;
                            this.formData_1 = []
                        })
                        .catch(() => {
                            this.snackbar.error = true;
                            this.snackbar.snackbar = true;
                            this.snackbar.success = false;
                        });
                }
            },
            form_2Post() {
                /*this.e6 = 4;*/
                this.formData_2_Object.List = this.formData_2;
                this.formData_2_Object.HotelCustomerID = this.snackbar.HotelCustomerID;
                this.formData_2_Object.gender = this.formData_2;
                this.formData_2_Object.roomID = this.formData_2;

                console.log("Data:", this.formData_2_Object);
                axios
                    .post(
                        "Hotel/InsertIntoHotelCustomerInformationAsync",
                        this.formData_2_Object
                    )
                    .then(response => {
                        console.log(response.data);
                        this.snackbar.success = response.data.success;
                        this.snackbar.number_of_second_form = 1;
                        this.snackbar.next_button = !this.snackbar.next_button;
                        this.formData_2_Object = {};
                        // this.e6++;
                        this.snackbar.next_button = false;
                        this.snackbar.save_button = true;
                        this.snackbar.text = "Customer Added Successfully !";
                        this.$emit("formResponse", this.snackbar);
                    })
                    .catch(() => {
                        this.snackbar.error = true;
                        this.snackbar.snackbar = true;
                        this.snackbar.success = false;
                        this.snackbar.text = "Internal Server Error, Please Contact Admin";
                    });
            },
            form_3Post() {
                // this.BillForm.HotelCustomerID = this.snackbar.HotelCustomerID
                let form = {
                    HotelCustomerID: this.snackbar.HotelCustomerID,
                    PaidAmount: parseFloat(this.BillForm.PaidAmount),
                    PaymentType: this.BillForm.PaymentType,
                    DueAmount: parseFloat(this.BillForm.DueAmount),
                    AmountToPay: parseFloat(this.snackbar.price + this.snackbar.ServicePrice)
                };

                axios
                    .post("Hotel/InsertIntoHotelCustomerBillAsync", form)
                    .then(response => {
                        console.log(response.data);
                        this.snackbar.success = response.data.success;
                        this.snackbar.next_button = false;
                        this.snackbar.save_button = true;
                        this.snackbar.text = "Customer Added Successfully !";
                        this.$emit("formResponse", this.snackbar);
                    })
                    .catch(() => {
                        this.snackbar.error = true;
                        this.snackbar.snackbar = true;
                        this.snackbar.success = false;
                        this.snackbar.text = "Internal Server Error, Please Contact Admin";
                    });
            },
            reset() {
                this.save_button = false;
                this.e6 = 1;
                this.$emit("FormClose", this.dialogue);
                this.snackbar.next_button = true;
            },
            checkRate(ActualRate, PaidPrice) {
                this.BillForm.DueAmount = (ActualRate.price + ActualRate.ServicePrice) - PaidPrice;
            },
            async GetRoomStatus() {
                let data = {
                    "roomIDs": "",
                    "bookedFromDate": this.formData_1.CheckInDate,
                    "bookedToDate": this.formData_1.CheckOutDate,
                    "isDropDown": true,

                };
                axios.post(
                    "Hotel/RoomStatusListAsync",
                    data
                ).then(response => {
                    this.rooms = response.data;
                }).catch(err => {
                    console.log(err)
                })
            }
        }
    };
</script>

<style scoped>
    .v-expansion-panel-header {
        background-color: #6061862b;
    }
</style>
