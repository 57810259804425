<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-container class="">
            <v-snackbar v-model="message" color="success" :timeout="callbackResponse.timeout" top>
                {{callbackResponse.message}}
                <v-btn dark text @click="message = false">Close</v-btn>
            </v-snackbar>
            <div slot="table-actions">

                <v-btn color="primary" @click.stop="createForm = true">{{$t('hotel_customer_form')}}</v-btn>

                   <v-row class="float-right">



                     <!--<v-btn @click="importExportModal" class="warning float-right mx-4">

                        Import
                    </v-btn>-->
                </v-row>

            </div>
              <v-dialog width="50%"
                      persistent
                      transition="dialog-bottom-transition"
                      v-model="snackbar.importExportComponent">

                    <ImportExportModal
                        :ieData="ieData"
                        @importExportModalResponse="importExportModalResponse"
                        v-if="snackbar.importExportComponent"
                    ></ImportExportModal>

            </v-dialog>

            <v-dialog v-model="editForm" fullscreen hide-overlay max-width="500px">
                <CustomerInformation
                        v-if="editForm"
                        :hotelCustomerData="hotelCustomerData"
                        @formResponse="onResponseUpdate"
                        @formResponseClose="onResponseClose"
                />
            </v-dialog>

            <v-dialog v-model="deleteData.dialogDelete" max-width="500px">
                <deleteListData :deleteFormData="deleteData" @formResponse="onDeleteResponse"></deleteListData>
            </v-dialog>

            <v-expansion-panels hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row col="12">
                                <v-row align="start" justify="start">

                                    <!--  <v-col class="d-flex" cols="12" sm="4">
        <v-autocomplete v-model="serverParams.ReportType"
                        :items="ReportTypes"
                        label="ReportType"
                        clearable
                        single-line
                        item-text="value"
                        item-value="value"
        ></v-autocomplete>
    </v-col>-->
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <v-text-field clearable
                                                      outlined
                                          dense
                                                      v-model="serverParams.passportNo"
                                                      :label="$t('passport_number')"></v-text-field>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="3">
                                        <v-text-field clearable
                                                      outlined
                                          dense
                                                      v-model="serverParams.FullName"
                                                      :label="$t('full_name')"></v-text-field>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="3">
                                        <v-menu v-model="snackbar.menu1"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                :close-on-content-click="false"
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="serverParams.FromDate"
                                                              :label="$t('check_in_date')"
                                                              clearable
                                                              outlined
                                          dense
                                                              prepend-inner-icon="event"
                                                              readonly
                                                              v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.FromDate">

                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="3" v-if="!reportIsMonthWise">
                                        <v-menu v-model="snackbar.menu2"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="serverParams.ToDate"
                                                              :label="$t('check_out_date')"
                                                              clearable
                                                              outlined
                                          dense
                                                              prepend-inner-icon="event"
                                                              readonly
                                                              v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.ToDate">

                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="12">
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-2" dark small color="primary" @click="loadItems">
                                            <v-icon dark> search</v-icon>
                                            {{$t('search')}}
                                        </v-btn>
                                        <v-btn @click.stop="onDownload" small class="success float-right mx-4">
                                            <v-icon>get_app</v-icon>
                                            {{$t('download')}}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <vue-good-table mode="remote"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-column-filter="onColumnFilter"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRecords"
                            :isLoading.sync="isLoading"
                            :pagination-options="{ enabled: true,mode: 'records' }"
                            :rows="rows"
                            :columns="columns">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row"
                          slot-scope="props">
                    <tbody>
                        <tr :class="props.row.isCustomerCheckOut? 'green accent-2' : 'purple lighten-5'"
                            v-for="rdata in rows"
                            :key="rdata.id">
                            <td>{{rdata.hotelCustomerID}}</td>
                            <td>{{rdata.hotelCustomerID}}</td>
                        </tr>
                    </tbody>
                </template>
                <template :class="props.row.isCustomerCheckOut? 'green accent-2' : 'purple lighten-5'" slot="table-row"
                          slot-scope="props">

                    <span v-if="props.column.field == 'actions'">
                        <v-icon small class="mr-2" @click="editItem(props.row)">edit</v-icon>
                        <v-icon small @click="deleteItem(props.row)">delete</v-icon>
                    </span>

                    <span v-else-if="props.column.field == 'roomReserved'">
                        <span v-if="props.row.roomReserved == 0">
                            Yes
                        </span>
                        <span v-else>
                            No
                        </span>
                    </span>

                    <span v-else-if="props.column.field == 'checkInDate'">{{props.row.checkInDate | moment("DD-MMM-YYYY")}}</span>
                    <span v-else-if="props.column.field == 'checkOutDate'">{{props.row.checkOutDate | moment("DD-MMM-YYYY")}}</span>
                    <span v-else-if="props.column.field == 'isCustomerCheckOut'">
                        <v-icon v-if="props.row.isCustomerCheckOut" small class="mr-2">done</v-icon>
                        <v-icon v-else>close</v-icon>
                    </span>
                </template>
            </vue-good-table>
        </v-container>

        <v-dialog v-model="createForm" fullscreen hide-overlay transition="dialog-bottom-transition">
            <stepper-form-component
                    v-if="createForm"
                    :dialogue="createForm"
                    :pendingData="pendingData"
                    @formResponse="onResponse"
                    @formResponseClose="onResponseClose"
            ></stepper-form-component>
        </v-dialog>

<!--        <v-dialog v-model="editForm" fullscreen hide-overlay transition="dialog-bottom-transition">-->
<!--            <editForm-->
<!--                    v-if="editForm"-->
<!--                    :hotelCustomerID="hotelCustomerID"-->
<!--                    @formResponse="onResponse"-->
<!--                    @formResponseClose="onResponseClose"-->
<!--            />-->
<!--        </v-dialog>-->

        <v-dialog v-model="showForm" fullscreen hide-overlay transition="dialog-bottom-transition">
            <showForm
                    v-if="showForm"
                    :hotelCustomerID="hotelCustomerID"
                    @formResponseClose="onResponseClose"
            />
        </v-dialog>
    </v-content>
</template>

<script>
    import axios from "axios";
    import createForm from "./create";
    // import editForm from "./edit";
    import showForm from "./show";
    import CustomerInformation from "./customerInformation";
    import deleteListData from "@/components/deleteModal";
    import ImportExportModal from "@/components/ImportExport/ImportExportModal"

    export default {
        name: "createAdminForm",
        props: ["success"],
        components: {
            "stepper-form-component": createForm,
            CustomerInformation,
            // editForm,
            showForm,
            deleteListData,
            ImportExportModal
        },
        computed: {
            items() {
                return [
                    {
                        text: this.$t('dashboard'),
                        disabled: false,
                        to: 'HotelDashboard',
                        exact: true

                    },
                    {
                        text: this.$t('hotel'),
                        disabled: true,
                    },
                    ]
            }
            },
        data() {
            return {
                /*user:2,*/
                ieData:{
                    import:"Hotel/Import",
                },
                callbackResponse: {
                    timeout: 6000
                },
                message: false,
                totalDesserts: 0,
                apiData: [],
                pendingData: [],
                loading: true,
                createForm: false,
                editForm: false,
                showForm: false,
                dialogCustomerInformation: false,
                dialogEditCustomerInformation: false,

                snackbar: {
                    importExportComponent: false,
                    snackbar: false,
                    text: "Internal Server Error,Please Contact Admin",
                    timeout: 200000,
                    menu2: false,
                    save_button: false,
                    next_button: true,
                    success: false,
                    error: false,
                    number_of_second_form: 1,
                    price: 0
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "countIndex",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10
                },
                columns: [
                    {
                        label: 's_no',
                        field: "countIndex",
                        width: '70px',
                        align: 'center'
                    },
                    {label: 'full_name', width: '280px', field: "fullName" },
                    { label: 'passport_number', width: '170px', field: "passportNumber"},
                    {label: 'check_in_date', width: '150px', field: "checkInDate"},
                    {label: 'check_out_date', width: '150px', field: "checkOutDate" },
                    { label:'checkout_status',width:'90px',field:"roomReserved"},
                    {label:'table.actions', width: '70px', field: "actions"}
                ],
                rows: [], province: [],
                division: [],
                filterEditedData: {},
                dialogData: {},
                formdata: [],
                totalRecords: 0,
                isLoading: false,
                deleteData: {
                    dialogDelete: false,
                     url:""
                },
            };
        },
        mounted() {
            this.loadItems();
        },
        destroyed() {
            console.log(this); // There's practically nothing here!
        },
        methods: {

            // async onImport() {
            //     var form = new FormData();

            //     form.append(
            //         "formFile",
            //         this.formdata.import
            //     );
            //     let ImportResponse = await axios.post("Hotel/Import", form);
            //     if (ImportResponse.data.success) {
            //         this.loadItems();
            //         this.formdata.import = null;
            //     } else {
            //         return false;
            //     }
            // },
             importExportModalResponse(data){
                            this.snackbar.importExportComponent = false
                            if(data.modal){
                                this.message = true
                                this.callbackResponse.message = data.message
                                this.loadItems();
                            }

            },
            importExportModal(){
                this.snackbar.importExportComponent = true
            },
            async onDownload() {
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field
                };

                await axios({
                    url: 'Hotel/Download',
                    method: 'POST',
                    responseType: 'blob',
                    data: param
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'ImportHotelFile.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            },
            onResponse(val) {
                if (val) {
                    this.dialogCustomerInformation = false;
                    this.message = true;
                    this.callbackResponse.message =
                        "Customer Information Updated Successfully.";
                    this.editForm = false;
                    this.createForm = false;
                    this.loadItems();
                }
            },

            onResponseUpdate(val) {
                if (val) {
                    this.loadItems();
                }
            },

            onDeleteResponse(data){
                console.log(data)
                if (data.message) {
                    this.dialogCustomerInformation = false;
                    this.message = true;
                    this.callbackResponse.message = "Customer Information Deleted Successfully.";
                    this.deleteData.dialogDelete = false
                    this.loadItems();
                } else {
                    this.callbackResponse.message = "";
                    this.deleteData.dialogDelete = false;
                }
            },
            onResponseClose() {
                console.log("here");
                this.dialogCustomerInformation = false;
                this.editForm = false;
                this.createForm = false;
                this.showForm = false;
            },
            deleteItem(props) {
                console.log(props)
                this.deleteData.dialogDelete = true;
                this.deleteData.url = "Hotel/DeleteHotelCustomerAsync/" + props.hotelCustomerID;
                this.loadItems();
            },
            editItem(item) {
                console.log("listClicked",item)
                this.editForm = true;
                this.hotelCustomerData = item;
            },
            showItem(item) {
                this.showForm = true;
                this.hotelCustomerID = item.hotelCustomerID;
            },
            closeForm() {
                this.editForm = false;
                this.showForm = false;
            },
            customerInformationMethod(props) {
                this.hotelCustomerID = props.hotelCustomerID;
                this.dialogCustomerInformation = true;
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },


            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            async loadItems() {
                this.loading = true;

                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    PassportNumber: this.serverParams.passportNo,
                    FullName: this.serverParams.FullName,
                    FromDate: this.serverParams.FromDate,
                    ToDate: this.serverParams.ToDate
                };
                axios.post("Hotel/GetHotelCustomersList", param).then(response => {
                    this.loading = false;
                    this.rows = response.data.data;
                    this.totalRecords = response.data.totalCount;
                }).catch(err => {
                    console.log(err)
                });
                console.log("load", this.apiData);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .pasForm {
        margin-top: 4em;
    }

    .square-checkout {
        height: 1.5em;
        width: 1.5em;
        background-color: #69f0ae;
    }

    .square-not-checkout {
        height: 1.5em;
        width: 1.5em;
        background-color: #f3e5f5;
    }
</style>
