<template>
    <v-card>
        <v-snackbar v-model="snackbar.snackbar" color="success" :timeout="snackbar.timeout" top>
            {{snackbar.message}}
            <v-btn dark text @click="snackbar.snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-toolbar dark color="primary">
            <v-toolbar-title>{{$t('customer_information')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="formClose">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-list three-line subheader>
            <v-expansion-panels>
                <v-expansion-panel v-for="(loop,index) in formData_2" :key="index">
                    <v-expansion-panel-header
                            class="subtitle-1 text-capitalize title-top"
                    >{{formData_2[index].fullName}}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="mt-5"> 
                        <v-row>
                            <!-- <v-text-field
            label="First Name"
            v-model="formData_2[index].firstName"
            clearable
            class="col-sm-6 col-md-4"
    ></v-text-field>
    <v-text-field
            label="Last Name"
            v-model="formData_2[index].lastName"
            clearable
            class="col-sm-6 col-md-4"
    ></v-text-field> -->

                            <v-text-field :label="$t('full_name')"
                                          v-model="formData_2[index].fullName"
                                          clearable
                                          class="col-sm-6 col-md-4"></v-text-field>

                            <v-autocomplete v-model="formData_2[index].country"
                                            :items="countries"
                                            :label="$t('country')"
                                            clearable
                                            single-line
                                            item-text="value"
                                            item-value="id"
                                            class="col-sm-6 col-md-4"
                                            @change="getState"></v-autocomplete>
                            <v-autocomplete v-model="formData_2[index].state"
                                            :items="state"
                                            :label="$t('state')"
                                            clearable
                                            single-line
                                            item-text="value"
                                            item-value="id"
                                            class="col-sm-6 col-md-4"></v-autocomplete>
                            <v-autocomplete v-model="formData_2[index].gender"
                                            :items="genderList"
                                            :label="$t('gender')"
                                            clearable
                                            single-line
                                            item-text="value"
                                            item-value="id"
                                            class="col-sm-6 col-md-4"></v-autocomplete>
                            <v-text-field :label="$t('age')"
                                          v-model="formData_2[index].age"
                                          clearable
                                          class="col-sm-6 col-md-4"></v-text-field>
                            <v-text-field :label="$t('citizenshipNumber')"
                                          v-model="formData_2[index].citizenShipNumber"
                                          clearable
                                          class="col-sm-6 col-md-4"></v-text-field>
                            <v-text-field :label="$t('passport_number')"
                                          v-model="formData_2[index].passportNumber"
                                          clearable
                                          class="col-sm-6 col-md-4"></v-text-field>
                            <v-text-field :label="$t('licenseNumber')"
                                          v-model="formData_2[index].licenseNumber"
                                          clearable
                                          class="col-sm-6 col-md-4"></v-text-field>
                            <v-text-field :label="$t('otherID')"
                                          v-model="formData_2[index].otherID"
                                          clearable
                                          class="col-sm-6 col-md-4"></v-text-field>

                            <v-menu v-model="formData_2[index].dateMenu1"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    class="col-sm-6 col-md-4"
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="formData_2[index].checkInDateString"
                                                  :label="$t('check_in_date')"
                                                  clearable
                                                  prepend-icon="event"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="formData_2[index].checkInDateString"></v-date-picker>

                            </v-menu>
                            <v-menu v-model="formData_2[index].dateMenu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    class="col-sm-6 col-md-4"
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="formData_2[index].checkOutDateString"
                                                  :label="$t('check_out_date')"
                                                  clearable
                                                  prepend-icon="event"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker @change="changeCustomerFormCheckoutDate(index)" v-model="formData_2[index].checkOutDateString"></v-date-picker>
                            </v-menu>
                            <!--                                    <v-menu v-model="infoData.dateMenu3"-->
                            <!--                                            :close-on-content-click="false"-->
                            <!--                                            :nudge-right="40"-->
                            <!--                                            transition="scale-transition"-->
                            <!--                                            offset-y-->
                            <!--                                            min-width="290px"-->
                            <!--                                            class="col-sm-6 col-md-4"-->
                            <!--                                    >-->
                            <!--                                        <template v-slot:activator="{ on }">-->
                            <!--                                            <v-text-field-->
                            <!--                                                    v-model="formData_2[index].IDValidity "-->
                            <!--                                                    label="ID Validity"-->
                            <!--                                                    clearable-->
                            <!--                                                    prepend-icon="event"-->
                            <!--                                                    readonly-->
                            <!--                                                    v-on="on"-->
                            <!--                                            ></v-text-field>-->
                            <!--                                        </template>-->
                            <!--                                        <v-date-picker v-model="formData_2[index].IDValidity ">-->
                            <!--                                        </v-date-picker>-->
                            <!--                                    </v-menu>-->
                            <!--                                    <v-menu v-model="infoData.dateMenu4"-->
                            <!--                                            :close-on-content-click="false"-->
                            <!--                                            :nudge-right="40"-->
                            <!--                                            transition="scale-transition"-->
                            <!--                                            offset-y-->
                            <!--                                            min-width="290px"-->
                            <!--                                            class="col-sm-6 col-md-4"-->
                            <!--                                    >-->
                            <!--                                        <template v-slot:activator="{ on }">-->
                            <!--                                            <v-text-field-->
                            <!--                                                    v-model="formData_2[index].visaValidity "-->
                            <!--                                                    label="Visa Validity"-->
                            <!--                                                    clearable-->
                            <!--                                                    prepend-icon="event"-->
                            <!--                                                    readonly-->
                            <!--                                                    v-on="on"-->
                            <!--                                            ></v-text-field>-->
                            <!--                                        </template>-->
                            <!--                                        <v-date-picker v-model="formData_2[index].visaValidity  ">-->
                            <!--                                        </v-date-picker>-->
                            <!--                                    </v-menu>-->
                            <v-text-field v-if="formData_2[index].passportNumber"
                                          readonly
                                          :label="$t('visa_validity')"
                                          v-model="validaty.visaValidity"
                                          clearable
                                          class="col-sm-6 col-md-4"></v-text-field>

                            <v-text-field v-if="formData_2[index].passportNumber"
                                          readonly
                                          :label="$t('id_validity')"
                                          v-model="validaty.visaValidity"
                                          clearable
                                          class="col-sm-6 col-md-4"></v-text-field>

                            <v-autocomplete v-model="formData_2[index].roomID"
                                            :items="selectedRoomData[index]"
                                            chips
                                            deletable-chips
                                            :label="$t('rooms')"
                                            clearable
                                            item-text="roomDisplay"
                                            item-value="roomID"
                                            class="col-sm-6 col-md-4"></v-autocomplete>
                            <v-text-field :label="$t('amount')"
                                          v-model="formData_2[index].amount"
                                          clearable
                                          class="col-sm-6 col-md-4"></v-text-field>

                            <v-switch v-model="formData_2[index].roomReservedStatus"
                                      :label="$t('room_reserved')"></v-switch>

                        </v-row>
                        <v-row justify="center" align-content="center">
                            <v-btn color="green" @click="updateCustomerInformationData(index)" dark v-if="formData_2[index].show">
                                {{$t('save')}}
                            </v-btn>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-list>
    </v-card>
</template>

<script>
    /*import { email, maxLength, required, sameAs } from "vuelidate/lib/validators";*/
    import axios from "axios";
    export default {
        name: "CustomerAdminFormForm",
        props: ["hotelCustomerData", "pendingData"],
        watch: {
            dialog: {
                handler: function (val) {
                    console.log("watch", this.dialog, val);
                    if (this.dialog == false) {
                        this.$emit("formResponse", val);
                    }
                    this.dialog = true;
                },
                deep: true
            }
        },
        data() {
            return {
                snackbar: {
                    snackbar:false,
                    timeout: 6000,
                    message:""
                },
                infoData:{
                    dateMenu4: false,
                    dateMenu3: false,
                    dateMenu1: false,
                    dateMenu2: false,
                },
                isCheckout:false,
                dialog: true,
                notifications: false,
                sound: true,
                widgets: false,
                userInfo: {},
                formData_2: {},
                rooms: [],
                BillForm: {},
                services: [],
                packages: [],
                RoomObject: {},
                countries: [],
                state: [],
                genderList: [],
                selectedRoomData:[],
                validaty:[],
                storeRoomIDList:[],
                commaRoomIDList:"",
            };
        },
        mounted() {
            this.formData();
        },
        methods: {

            changeCustomerFormCheckoutDate(index){

                let data = {
                    "bookedFromDate": this.formData_2[index].checkInDateString,
                    "bookedToDate": this.formData_2[index].checkOutDateString,
                    "isDropDown": true,
                    "RoomIDList": this.commaRoomIDList,
                    "personInformationID": this.formData_2[index].personInformationID
                };
                console.log(data,this.formData_2[index])
                axios.post(
                    "Hotel/RoomStatusListAsync",
                    data
                ).then(response=>{
                    this.selectedRoomData[index] = response.data;
                }).catch(err=>{
                    console.log(err)
                })
            },


            getState(val) {

                axios.get('Shared/StateListByCountryCode?CountryCode=' + val).then(response=>{
                    this.state = response.data
                });
            },
            formClose(val) {
                console.log(val)
                this.$emit("formResponseClose", val);
            },

            updateCustomerInformationData(index) {

                this.formData_2[index].checkInDate = this.formData_2[index].checkInDateString
                this.formData_2[index].checkOutDate = this.formData_2[index].checkOutDateString


                axios.post('Hotel/UpdateHotelPersonInformation',{person:this.formData_2[index]}).then(response=>{
                    console.log(response)
                    if(response.data.success){
                        this.snackbar.snackbar =true
                        this.snackbar.message = "Updated Customer Information"
                        this.$emit("formResponse", this.snackbar);

                    }
                }).catch(response=>{
                    console.log(response.data.success)

                    this.snackbar.snackbar =true
                    this.snackbar.message = "Something Went wrong !!!"

                })

            },

            async formData() {
                axios.get("Shared/CountryList").then(response=>{
                    this.countries = response.data;
                }).catch(err=>{
                    throw err
                })

                axios.get("Shared/GenderList").then(res=>{
                    this.genderList = res.data;
                }).catch(err=>{
                    throw err
                })

                axios.post(
                    "Hotel/GetPersonInfoWithRoomInfoViewModel", {
                    PersonIDs: this.hotelCustomerData.personIDs,
                        RoomIDs: this.hotelCustomerData.roomIDs
                }).then(res=>{
                    // this.selectedRoomData = res.data.roomList
                    this.formData_2 = res.data.personList;
                    res.data.personList.forEach((response,index)=>{
                        if(response.country){
                            this.getState(response.country)
                        }
                        this.selectedRoomData[index] = res.data.roomList
                        this.storeRoomIDList = res.data.roomList
                    })

                    let arr = []
                    this.storeRoomIDList.filter(response=>{
                        arr.push(response.roomID)
                    })
                    this.commaRoomIDList = arr.join(",")

                })
            }
        }
    };
</script>

<style lang="scss" scoped>
    .title-top{
    background-color: #649eedbd !important;
        color: white !important;
    }
</style>
